import React, {Component} from 'react'

export default class LargeCard extends Component {
    constructor(props) {
        super(props);
        this.displayExperience = this.displayExperience.bind(this);
        this.displayProject = this.displayProject.bind(this);
    }

    displayExperience(info, rows) {
        return (
            <div className='largecard-div'>
            <p className='largecard-position'><b>{info.position}</b></p>
            <p className='largecard-title'><i>{info.title}</i></p>
            <p className='largecard-date'>{info.date}</p>
            <ul className='largecard-description'>
                {rows}
            </ul>
            <p> </p>
            <p> </p>
            <p> </p>
        </div>
        );
    }

    displayProject(info, rows) {
        return (
            <div className = 'largecard-div'>
            <p className='largecard-position'><b>{info.title}</b></p>
            <ul className='largecard-description'>
                {rows}
            </ul>
            <p> </p>
            <p> </p>
            <p> </p> 
        </div>
        );
    }

    render() {
        const {info} = this.props;
        var rows = [];
        var displayInfo = null;
        for(var i = 0; i < info.description.length; i++)
            rows.push(<li key = {info.description[i]}>{info.description[i]}</li>);
        if(info.date != null)
            displayInfo = this.displayExperience(info, rows);
        else 
            displayInfo = this.displayProject(info, rows);
        return (
            <div>
                {displayInfo}
            </div>
        );  
    }
}