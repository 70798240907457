import React from 'react'
import './App.css'
import Resume from './components/Resume.js'

function App() {
  return (
    <div className="body">
      <title>Test</title>
      <Resume />
    </div>
  );
}

export default App;
