import React, {Component} from 'react'
import axios from 'axios'

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiData: ''
        }
    }

    componentDidMount() {
        const api = 'https://h6176mc196.execute-api.us-east-1.amazonaws.com/staging';
        axios.get(api).then(response => {
            this.setState({apiData: response.data.body});
        }).catch(error => {
            console.log(error.response);
        })
    }

    render() {
        return (
            <div className="resume-header-div">
                <h1 className="resume-name">Nicholas Sutton</h1>
                <h2 className="resume-description">Software Engineer</h2>
                <h6 className="visitor-count">Visitor Counter: {this.state.apiData}</h6>
            </div>
        );
    }
}