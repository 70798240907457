import React, {Component} from 'react'

export default class SmallCard extends Component {
    constructor(props) {
        super(props);
        this.displayContact = this.displayContact.bind(this);
        this.displayEducation = this.displayEducation.bind(this);
        this.displaySkills = this.displaySkills.bind(this);
    }

    displayContact(info) {
        return (
            <div className='smallcard-div'>
                <p className='smallcard-p'>{info.email}</p>
                <p className='smallcard-p'>{info.location}</p>
                <a className='smallcard-a' href={info.linkedIn}>LinkedIn</a>
            </div>
        );
    }

    displayEducation(info) {
        return (
            <div className='smallcard-div'>
                <p className='smallcard-p'><b>{info.school[1]}</b></p>
                <p className='smallcard-p'><i>{info.degree[1]}, {info.major}</i></p>
                <p className='smallcard-p'>{info.date[1]}</p>
                <p className='smallcard-p'>{info.location[1]}</p>
                <p> </p>
                <p> </p>
                <p className='smallcard-p'><b>{info.school[2]}</b></p>
                <p className='smallcard-p'><i>{info.degree[2]}, {info.major}</i></p>
                <p className='smallcard-p'>{info.date[2]}</p>
                <p className='smallcard-p'>{info.location[2]}</p>
                <p> </p>
                <p> </p>
                <p className='smallcard-p'><b>{info.school[0]}</b></p>
                <p className='smallcard-p'><i>{info.degree[0]}, {info.major}</i></p>
                <p className='smallcard-p'>{info.date[0]}</p>
                <p className='smallcard-p'>{info.location[0]}</p>
            </div>
        )
    }

    displaySkills(info) {
        return (
           <div className='smallcard-div'>
               <p className='smallcard-p'>{info.skills[0]}</p>
               <p className='smallcard-p'>{info.skills[1]}</p>
               <p className='smallcard-p'>{info.skills[2]}</p>
               <p className='smallcard-p'>{info.skills[3]}</p>
               <p className='smallcard-p'>{info.skills[4]}</p>
               <p className='smallcard-p'>{info.skills[5]}</p>
               <p className='smallcard-p'>{info.skills[6]}</p>
               <p className='smallcard-p'>{info.skills[7]}</p>
           </div>
        );
    }

    render() {
        const {info} = this.props;
        var displayInfo = null;
        if(info.email != null) {
            displayInfo = this.displayContact(info);
        } else if(info.degree != null) {
            displayInfo = this.displayEducation(info);
        } else {
            displayInfo = this.displaySkills(info);
        }
        return (
            <div>
                <div className='smallcard-title'>
                    <h2 className='smallcard-h2'>{info.title}</h2>
                </div>
                {displayInfo}
            </div>
        );

    }
}