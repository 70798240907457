import React, {useState} from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Header from './Header.js'
import SmallCard from './SmallCard.js'
import LargeCard from './LargeCard.js'
import "bootstrap/dist/css/bootstrap.min.css";

const contactInfo = {
    title: 'Contact',
    email: 'nsutton98@gmail.com',
    phone: '(714)-599-3598',
    location: 'Anaheim, CA',
    linkedIn: 'https://www.linkedin.com/in/nick-sutton-283ba11a1/',
};

const educationInfo = {
    title: 'Education',
    degree: ['A.S', 'B.S.', 'Education Abroad'],
    major: 'Computer Science',
    school: ['Santiago Canyon College', 'California State University, Long Beach', 'Glasgow Caledonian University'],
    date: ['August 2016 - June 2019', 'August 2019 - June 2022', 'January 2022 - June 2022'],
    location: ['Orange, CA', 'Long Beach, CA', 'Glasgow, Scotland']
};

const skillsInfo = {
    title: 'Skills',
    skills: ['Java', 'Python (Django)', 'C++', 'JavaScript (ReactJS)', 'Android Studio', 'MySQL', 'HTML/CSS', 'Git']
};

const ccaExp = {
    title: 'Collegiate Carball Association',
    position: 'Technology Development Intern',
    date: 'May 2021 - January 2022',
    description: ['Built and maintained a front-end dashboard using ReactJS to display current and previous match data', 
                'Collaborated with various front-end/back-end engineers to incorporate live data processing through a custom API'],
};

const pizzaExp = {
    title: 'Pizza Press',
    position: 'Editor',
    date: 'December 2020 - January 2022',
    description: ['Fast-paced customer service environment in the food industry']
};

const kumonExp = {
    title: 'Kumon',
    position: 'Tutor',
    date: 'April 2017 - September 2020',
    description: ['Tutored math and reading to children aged 5 to 14', 
                'Math tutoring ranged from simple arithmetic to pre-calculus', 
                'Reading tutoring ranged from basic sentence building to advanced reading comprehension']
};

const pylusdExp = {
    title: 'Placentia-Yorba Linda Unified School District',
    position: 'Technology Department Intern',
    date: 'June 2015 - August 2015',
    description: ['Worked under various school district staff to handle software and hardware problems',
                'Travelled to multiple schools to replace computer parts, projectors, etc.',
                'Responsible for registering new Chromebooks through the school domain',
                'Answered questions through the customer service helpdesk']
};

const seniorProj = {
    title: 'CSULB Senior Project',
    description: ["Web based application (Django) to plan around a user's schedule and determine their most optimal productivity time", 
    'Utilized machine learning to send reminder notifications when the user should complete a task in their schedule', 
    'Collaborated with five other classmates over the course of a year'],
};

const traffProj = {
    title: 'Traffic Scotland',
    description: ['Android application that displays and updates traffic incidents based on live XML data from trafficscotland.org',
    'Displays current incidents, current roadwork, and planned roadwork throughout the country',
    'Uses the Google Maps API to display exact locations of incidents']
};

const mlProj = {
    title: 'NHL Match Predictor',
    description: ['Anvil Works web application that uses machine learning to predict National Hockey League matchup winners',
    'Linked with Google Colab notebook to build and train machine learning model with previous four years of match data',
    'Depending on win/loss predictions, the application gives advice on what strategies should be changed in order to predict a win']
};

const dashboardProj = {
    title: 'CCA Stream Overlay Dashboard',
    description: ['Built and maintained a ReactJS application that displays current and previous Collegiate Rocket League match data',
    'Handled the front-end work along with two others'],
};

const databaseProj = {
    title: 'Database Project',
    description: ["Database application that uses the Java Persistence API to store and access data from a relational database storing information about recipes created by chefs and reviewed by critics", 
    'Worked with two other individuals over two months'],
};

export default function Resume () {
    const [key, setKey] = useState('work-experience');
    return (
        <div>
            <Header />
            <div className='resume-body-div'>
                <div className='resume-details-div'>
                    <p>**Front-end currently under revision**</p>
                    <SmallCard info = {contactInfo} />
                    <SmallCard info = {educationInfo} />
                    <SmallCard info = {skillsInfo} />
                </div>
                <div className='resume-work-div'>
                    <Tabs id='controlled-tab' activeKey={key} onSelect= {(k) => setKey(k)} className='mb-3'>
                        <Tab eventKey='work-experience' title='Work Experience'>
                            <h2 className='experience'>Work Experience</h2>
                            <LargeCard info = {ccaExp} />
                            <LargeCard info = {pizzaExp} />
                            <LargeCard info = {kumonExp} />
                            <LargeCard info = {pylusdExp} />
                        </Tab>
                        <Tab eventKey='projects' title='Projects'>
                            <h2 className='project'>Projects</h2>
                            <LargeCard info = {seniorProj} />
                            <LargeCard info = {traffProj} />
                            <LargeCard info = {mlProj} />
                            <LargeCard info = {dashboardProj} />
                            <LargeCard info = {databaseProj} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}